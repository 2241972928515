import React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"

const Mpd = () => (
  <Layout>
    <Seo title="Sorted | MPD" keywords={[`Sorted`, `MPD`]} />
    <h1>MPD</h1>
  </Layout>
)

export default Mpd
